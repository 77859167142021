@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "MonumentExtended-Regular";
    src: url("../font/MonumentExtended/MonumentExtended-Regular.otf");
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    color: #100F0E;
    font-size: 14px;
    user-select: none;
}

*::-webkit-scrollbar {
  display: none;
  width: 4px;
  background-color: #1c2127;
}

/* tr{border-bottom: 1px solid red !important;} */